.App {
    font-family: sans-serif;
    text-align: center;

}

@font-face {
    font-family: 'Montserrat';
    src: local('Lato'), url(./assets/fonts/Montserrat-VariableFont_wght.ttf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    src: local('Lato'), url(./assets/fonts/Montserrat-Italic-VariableFont_wght.ttf) format('opentype');
}


.page1 {
    font-family: Montserrat;
    z-index: 1;
    background-color: black;
    // background-image: url('../assets/wallpaper.png');
    background-size: cover;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 72px;
        color: white;
        text-shadow: 4px 4px black;
        font-family: fantasy;
    }
}

.page2 {
    font-family: Montserrat;
    background-color: #161922;
}

.page3 {
    font-family: Montserrat;
    background-color: white;
}

.page4 {
    font-family: Montserrat;
    background-color: #09111E;
}

.page5 {
    font-family: Montserrat;
    background-color: #09111E;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.btn {
    border: 1px solid yellow;
    padding: 0 24px;
    color: yellow;
    border-radius: 12px;
    font-size: 24px;
}