.product-container {

  .header {
    font-size: 54px;
  }

  .appItems {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    button {
      padding: 32px;
      max-width: 280px;

      img {
        height: 100px;
        width: 100px;
        object-fit: contain;
      }

      &:hover {
        border-radius: 12px;
        background-color: lightgray;
      }
    }
  }


  @media only screen and (max-width : 768px) {
    .header {
      font-size: 44px;
    }
  }

  @media only screen and (max-width : 412px) {
    .header {
      font-size: 24px;
    }

    .appItems {
      button {
        img {
          height: 50px;
          width: 50px;
          object-fit: contain;
        }

      }
    }
  }
}