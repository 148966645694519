.about-container {
  padding: 2vw;
  color: white;

  h1 {
    font-size: 3.8vw;
    font-family: Montserrat;
    white-space: nowrap;
    margin: 24px;
    text-align: center;
  }

  .description {
    margin: 24px;

    p {
      font-family: Montserrat;
      font-size: 20px;
      // font-size: 1vw;
      text-align: justify;
    }
  }

  .btn {
    border: 1px solid yellow;
    padding: 0 24px;
    color: yellow;
    border-radius: 12px;
    font-size: 24px;
  }

  @media only screen and (max-width : 768px) {
    .description {
      p {
        font-size: 16px;
      }
    }
  }

  @media only screen and (max-width : 412px) {
    .description {
      p {
        font-size: 12px;
      }
    }
  }
}