.nav-container {

    .nav-mobile {
        visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 24px;
        position: fixed;
        z-index: 1;
        width: 100%;
    }

    .nav-web {
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        position: fixed;
        width: 100%;
        padding: 24px;

        li {
            text-align: center;
            list-style-type: none;
            color: #FFF;
            font-size: 18px;
        }

        ul {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            li {
                width: 100px;
                text-align: center;
                list-style-type: none;
                color: #FFF;
                font-size: 18px;
                text-shadow: 2px 2px black;
                cursor: pointer;
            }
        }
    }

    @media only screen and (max-width : 560px) {
        .nav-mobile {
            visibility: visible;
        }

        .nav-web {
            display: none;
        }
    }
}