.blogs-container {
  .content {

    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;

      .row-item {
        // background-color: black;
        max-width: 400px;
        border-radius: 12px;
        margin: 24px;

        iframe {
          width: 100%;
          height: 340px;
          border-radius: 12px;
        }

        h1 {
          color: white;
          font-weight: bold;
          font-size: 16px;
        }

        h3 {
          color: white;
          font-weight: bold;
          font-size: 14px;
          opacity: 0.7
        }

        .p-content {
          color: white;
          opacity: 0.6;
          margin-top: 24px;
          font-size: 12px;
        }
      }
    }
  }
}