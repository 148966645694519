.home-container {
  width: 100%;
  height: 700px;
  position: relative;

  :global(.react-parallax-content) {
    // height: 100%;
    position: relative !important;
  }

  .content {
    padding: 250px 12vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    color: white;

    .type-animation {
      z-index: 1;

      span {
        text-shadow: 4px 4px 20px black;
      }
    }

    .slick-text {
      padding: 12px;

      h3 {
        font-size: 24px;
        text-align: justify;
        text-shadow: 4px 4px 20px black;
      }
    }

    .actions {
      z-index: 1;

      button {
        margin: 12px;
      }
    }

    #slick {
      width: 600px;
      margin: 24;
      z-index: 1;
    }


  }

  .floating-image {
    position: absolute;
    right: 8vw;
    bottom: -210px;
  }

  @media only screen and (max-width : 3800px) {
    .floating-image {
      position: absolute;
      right: 18vw;
      bottom: -210px;
    }
  }

  @media only screen and (max-width : 3300px) {
    .floating-image {
      position: absolute;
      right: 24vw;
      bottom: -210px;
    }
  }

  @media only screen and (max-width : 2500px) {
    .floating-image {
      position: absolute;
      right: 15vw;
      bottom: -210px;
    }
  }

  @media only screen and (max-width : 2200px) {
    .floating-image {
      position: absolute;
      right: 10vw;
      bottom: -210px;
    }
  }

  @media only screen and (max-width : 1700px) {
    .floating-image {
      position: absolute;
      right: 2vw;
      bottom: -210px;
    }
  }

  @media only screen and (max-width : 1440px) {
    .floating-image {
      position: absolute;
      right: 2vw;
      bottom: -210px;
    }
  }


  @media only screen and (max-width : 1024px) {
    .floating-image {
      display: none;
    }
  }

  @media only screen and (max-width : 768px) {
    .content {
      #slick {
        display: none;
      }

      .actions {
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          margin: 12px;
        }
      }
    }

    :global(.react-parallax-content) {
      height: 100%;
    }
  }

}